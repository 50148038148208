/* eslint-disable camelcase */
import React, { useReducer, useState, useEffect, useCallback } from 'react';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Col, Row, Button, Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import helpers from 'helpers/proposal';
import _ from 'lodash';
import moment from 'moment';

import blockStyle from 'pages/Proposal/components/Publish/helpers/blockStyle';
import { SignatureModals } from 'pages/Proposal/components/Publish/modals';
import useWindowDimensions from 'hooks/useWindowDimensions';
import utils from 'utils/utils';
import { AutoTextSize } from 'auto-text-size';

export const initialState = {
  showOrHideSignSignature: false,
  testSign: false,
  thanksSign: false,
  thanksClientSign: false,
  isSigning: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setShowOrHideSignSignature':
      return {
        ...state,
        showOrHideSignSignature: action.showOrHideSignSignature,
      };
    case 'setTestSign':
      return { ...state, testSign: action.testSign };
    case 'setThanksSign':
      return { ...state, thanksSign: action.thanksSign };
    case 'setThanksClientSign':
      return { ...state, thanksClientSign: action.thanksClientSign };
    case 'setIsSigned':
      return { ...state, isSigning: action.isSigning };
    default:
      return state;
  }
};

const findSignatureLayOut = ({ position, screenSize }) => {
  const signatureLayout = {
    signatureTitleSpan: 8,
    signatureItemsSpan: 16,
    signatureTitleStyle: {},
  };
  if (!position) return signatureLayout;
  if (screenSize === 'sm' || screenSize === 'xs') {
    signatureLayout.signatureTitleSpan = 24;
    signatureLayout.signatureItemsSpan = 24;
  }
  if (position === 'top') {
    signatureLayout.signatureTitleSpan = 24;
    signatureLayout.signatureItemsSpan = 24;
  } else if (position === 'top-middle') {
    signatureLayout.signatureTitleSpan = 24;
    signatureLayout.signatureItemsSpan = 24;
    signatureLayout.signatureTitleStyle = {
      justifyContent: 'center',
      display: 'flex',
    };
  } else if (position === 'none') {
    signatureLayout.signatureTitleStyle = {
      display: 'none',
    };
    signatureLayout.signatureTitleSpan = 24;
    signatureLayout.signatureItemsSpan = 24;
  } else if (position === 'large') {
    signatureLayout.signatureTitleSpan = 4;
    signatureLayout.signatureItemsSpan = 20;
  }

  return signatureLayout;
};

// Rendering the published signature section
// There is some logic here to show a dialog for the client to sign the Proposal (when in /c/ url mode)

const PublishSignatureSection = ({
  name,
  proposal,
  user,
  setProp,
  updateProposalClient,
  isClient,
  configText,
  showValidationErrorFunction,
  downloadPdf,
  isSavingForm,
  userWixPreview,
  templateWixPreview,
  wixPreview,
  isEditingModal,
  onRedirect,
  clientWixPreview,
  isHistory,
}) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, onRedirect });
  const [clientSignatureClicked, setClientSignatureClicked] = useState('');
  const { width, screenSize } = useWindowDimensions();
  const { disableSig, draft } = proposal;
  const { pathname } = useLocation();

  const [signatureDisabled, setSignatureDisabled] = useState(false);

  const getProposalFormDetails = useCallback(async () => {
    const sectionOrder = proposal.draft.sectionorder;
    const allForms = [];

    sectionOrder.forEach((sectionName) => {
      const section = proposal?.draft[sectionName];
      const sectionRawBlocks = section?.raw?.blocks;

      if (sectionRawBlocks?.length) {
        sectionRawBlocks.forEach((sectionRawBlock) => {
          if (sectionRawBlock.type === 'atomic') {
            const formFields = [];

            sectionRawBlock.entityRanges.forEach((entityRange) => {
              const entityMap = section?.raw?.entityMap[entityRange.key];

              if (entityMap?.type === 'form') {
                entityMap?.data?.data?.forEach((field) => {
                  field.selected
                    ? formFields.push({
                        key: field.key,
                        value: field.value,
                        required: field.required ? field.required : false,
                        section: sectionName,
                      })
                    : '';
                });
              }
            });
            if (formFields.length) {
              allForms.push(...formFields);
            }
          }
        });
      }
    });

    return allForms;
  }, [proposal.draft]);

  useEffect(() => {
    const { enablePayment, clientPayableAmount, payment, stripe, state } = proposal;

    if (
      wixPreview ||
      (state === 'edit' && userWixPreview) ||
      (enablePayment === 'before_sign' &&
        stripe !== '' &&
        clientPayableAmount !== null &&
        payment === null)
    ) {
      setSignatureDisabled(true);
    } else {
      setSignatureDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkForm = useCallback(
    async (clientType) => {
      if (isSavingForm) {
        setClientSignatureClicked(clientType);
      } else {
        const allForms = await getProposalFormDetails();
        let goForward = true;
        for (let i = 0; i < allForms.length; i++) {
          if (allForms[i].required && allForms[i].value === '') {
            showValidationErrorFunction(allForms[i].section);
            goForward = false;
            break;
          }
        }
        if (goForward) {
          dispatch({
            type: 'setShowOrHideSignSignature',
            showOrHideSignSignature: clientType,
          });
        }
        setClientSignatureClicked('');
      }
    },
    [getProposalFormDetails, isSavingForm, showValidationErrorFunction]
  );

  useEffect(() => {
    if (!isSavingForm && clientSignatureClicked) {
      checkForm(clientSignatureClicked);
    }
  }, [isSavingForm, clientSignatureClicked, checkForm]);

  if (disableSig) {
    return null;
  }

  const headersection = proposal.draft.header;
  const section = proposal.draft[name];

  if (!proposal || !section) {
    return null;
  }

  let namehtmlclean;
  if (section && section.signature) {
    namehtmlclean = section.signature;
  } else {
    let namehtml = headersection?.name || '';
    if (headersection?.rawname) {
      const namecs = convertFromRaw(headersection.rawname);
      namehtml = stateToHTML(namecs);
    }
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = namehtml;
    namehtmlclean = tempDiv.textContent;
  }

  let titlehtml = section?.title || '';
  if (section?.rawtitle) {
    const titlecs1 = convertFromRaw(section.rawtitle);
    titlehtml = stateToHTML(titlecs1, blockStyle({ language: proposal.language }));
  }

  const sigdata = section
    ? {
        myname: section.rawmyname
          ? stateToHTML(
              convertFromRaw(section.rawmyname),
              blockStyle({ language: proposal.language })
            )
          : section.myname,
        name: section.rawname
          ? stateToHTML(
              convertFromRaw(section.rawname),
              blockStyle({ language: proposal.language })
            )
          : section.name,
        additionalName: section?.additionalSignature?.rawname
          ? stateToHTML(
              convertFromRaw(section.additionalSignature.rawname),
              blockStyle({ language: proposal.language })
            )
          : section?.additionalSignature?.name,
        signatureBackgroundColor:
          section?.signatureBackgroundColor || helpers.torgb('#005aff', false),
        signatureTextColor: section?.signatureTextColor || 'white',
        additionalSignatureBackgroundColor:
          section?.additionalSignatureBackgroundColor || helpers.torgb('#005aff', false),
        additionalSignatureTextColor: section?.additionalSignatureTextColor || 'white',
      }
    : {
        name: proposal.client && proposal.client.contact,
        myname: proposal.name,
      };

  const countcapitalz = (s) => {
    return s
      ? _.reduce(
          _.map(s, (c) => (c === c.toUpperCase() ? 1 : 0)),
          (a, b) => a + b
        )
      : 0;
  };

  let weirdCache = null;

  const getsigname = function (x) {
    let cls = null;
    if (!x) {
      cls = 'normalsig';
    } else if (/(http(s?)):\/\//gi.test(x)) {
      cls = 'imgsig';
      x = <img src={x} alt={x} />;
    } else {
      cls = 'normalsig';
      x.split(' ').forEach((w) => {
        if (countcapitalz(w) >= 4) {
          if (countcapitalz(w) >= 6) {
            cls = 'verysmallsig';
            weirdCache = cls;
          } else {
            cls = 'smallsig';
            if (!weirdCache) {
              weirdCache = cls;
            }
          }
        }
      });

      x = <AutoTextSize maxFontSizePx={40}>{x}</AutoTextSize>;
    }

    return <div className={`signaturetext ${cls}`}>{x}</div>;
  };

  const fetchClientSignature = (name, clientType) => {
    let btnText = configText('sign proposal button text');

    if (clientType === 'main' && draft?.signature?.btnText) {
      btnText = draft.signature.btnText || btnText;
    }
    if (clientType === 'secondary') {
      // if 2nd button text is empty then try showing 1st button text
      if (draft?.signature.hasOwnProperty('btnText2')) {
        btnText = draft?.signature?.btnText2 || draft.signature?.btnText || btnText;
      }
    }

    // eslint-disable-next-line no-constant-condition
    return isClient || true ? (
      name ? (
        getsigname(name)
      ) : pathname.includes('/PDF/') ? (
        ''
      ) : (
        <Tooltip
          placement={signatureDisabled && proposal.enablePayment === 'before_sign' ? 'top' : null}
          title={
            signatureDisabled && proposal.enablePayment === 'before_sign'
              ? 'You need to Make Payment before signing this Proposal'
              : null
          }
          trigger="hover">
          <Button
            disabled={isHistory || signatureDisabled || userWixPreview || templateWixPreview}
            loading={isSavingForm && clientSignatureClicked}
            className="sign-proposal-button"
            style={{
              backgroundColor:
                clientType === 'main'
                  ? sigdata.signatureBackgroundColor
                  : sigdata.additionalSignatureBackgroundColor,
              color:
                clientType === 'main'
                  ? sigdata.signatureTextColor
                  : sigdata.additionalSignatureTextColor,
              borderRadius: 4 + 'px',
              fontWeight: 600,
              fontSize: 16 + 'px',
            }}
            onClick={() => checkForm(clientType)}>
            {btnText}
          </Button>
        </Tooltip>
      )
    ) : (
      getsigname(name ? name || <span>&nbsp;</span> : <span>&nbsp;</span>)
    );
  };

  const getTitlePosition =
    draft.titleFont && draft.titleFont.position ? draft.titleFont.position : false;
  const proposalTextCell = helpers.getStyleObj(proposal, 'body2');
  const signatureLayOut = findSignatureLayOut({
    position: getTitlePosition,
    width,
    screenSize,
  });
  let hideUserSignature = false;
  if (section?.hidesig) {
    hideUserSignature = section.hidesig;
  }

  return (
    <nobreak is="separator">
      <div
        id="proposal-signaturesection"
        className={`proposal-signaturesection disable-${!!disableSig}`}>
        <SignatureModals
          state={state}
          dispatch={dispatch}
          proposal={proposal}
          user={user}
          setProp={setProp}
          updateProposalClient={updateProposalClient}
          isClient={isClient}
          configText={configText}
          downloadPdf={downloadPdf}
          isEditingModal={isEditingModal}
          clientWixPreview={clientWixPreview}
        />
        <div className="ant-row">
          <Col
            span={signatureLayOut.signatureTitleSpan}
            style={signatureLayOut.signatureTitleStyle}>
            <h1
              className="section-title"
              style={helpers.getStyleObj(proposal, 'title3')}
              dangerouslySetInnerHTML={{ __html: titlehtml }}
            />
          </Col>
          <Col
            span={signatureLayOut.signatureItemsSpan}
            className="proposal-signaturesection-content"
            style={Object.assign(proposalTextCell, { marginBottom: '1.5em' })}>
            <Row>
              <Col span={12}>
                {!hideUserSignature && (
                  <Col span={24} className="signature-col">
                    {getsigname(namehtmlclean)}
                  </Col>
                )}
                {!hideUserSignature && (
                  <Col span={24}>
                    <div
                      className="signature-name"
                      dangerouslySetInnerHTML={{
                        __html: utils.changeVariable(proposal?.draft?.variables, sigdata.myname),
                      }}
                    />
                  </Col>
                )}
              </Col>
              <Col span={12}>
                <Col
                  span={!hideUserSignature ? 24 : 24}
                  className={`signature-btn-col ${
                    proposal?.signature?.name ? 'signature-btn-col-text' : ''
                  }`}>
                  {fetchClientSignature(proposal?.signature?.name, 'main')}
                </Col>
                <Col span={!hideUserSignature ? 24 : 24}>
                  <div
                    className="signature-name"
                    dangerouslySetInnerHTML={{
                      __html: utils.changeVariable(proposal?.draft?.variables, sigdata.name),
                    }}
                  />

                  {section?.additionalSignature?.name && (
                    <>
                      <Col
                        span={!hideUserSignature ? 24 : 24}
                        className={`additional-signature-btn-col ${
                          proposal?.signature?.additionalSignature ? 'signature-btn-col-text' : ''
                        }`}>
                        {fetchClientSignature(
                          proposal?.signature?.additionalSignature,
                          'secondary'
                        )}
                      </Col>
                      <div
                        className="signature-name"
                        dangerouslySetInnerHTML={{
                          __html: utils.changeVariable(
                            proposal?.draft?.variables,
                            sigdata.additionalName
                          ),
                        }}
                      />
                    </>
                  )}
                </Col>
              </Col>
            </Row>
          </Col>
        </div>
        {proposal.signature && proposal.signature.date ? (
          <div className="pdf-signed-info">
            <span>
              This proposal was signed digitally on{' '}
              {moment(proposal.signature.date).utc().format('MMM Do, YYYY, HH:mm A')} GMT+0
            </span>
          </div>
        ) : null}
      </div>
    </nobreak>
  );
};

PublishSignatureSection.defaultProps = {
  name: '',
  setProp: () => {},
  userWixPreview: false,
  isEditingModal: false,
};

PublishSignatureSection.propTypes = {
  proposal: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string,
  isClient: PropTypes.bool.isRequired,
  configText: PropTypes.func.isRequired,
  setProp: PropTypes.func,
  showValidationErrorFunction: PropTypes.func,
  downloadPdf: PropTypes.func.isRequired,
  userWixPreview: PropTypes.bool,
  templateWixPreview: PropTypes.bool,
  isEditingModal: PropTypes.bool,
  clientWixPreview: PropTypes.bool,
};

export default PublishSignatureSection;
