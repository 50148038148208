import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import BackIcon from 'components/Icons/BackIcon';

const HistoryHeader = ({
  handleBack,
  backupPreviewId,
  backupPreviewLoading,
  restoreProposal,
  restoring,
  approved,
}) => {
  const [restored, setRestored] = useState(null);

  const onRestore = () => {
    setRestored(backupPreviewId);
    restoreProposal(backupPreviewId);
  };

  return (
    <>
      <Button
        className="back-button cancel"
        type="primary"
        onClick={handleBack}
        icon={<BackIcon />}>
        BACK
      </Button>
      <div className="spacer" />
      {
        //  show restore button for
        //   1. prevent restore if already approved
        //   2. backupPreviewId is set (backup id is required for restore)
        //   3. backupPreviewId is not = 'live' (live proposal can't be restored)
        !approved && backupPreviewId && backupPreviewId !== 'live' && (
          <Button
            className="publish-button publish-button-right restore-btn"
            loading={restoring.state === 'restoring'}
            disabled={backupPreviewLoading || restored === backupPreviewId} // (since already restored so we hide the button)
            onClick={onRestore}>
            RESTORE
          </Button>
        )
      }
    </>
  );
};

HistoryHeader.propTypes = {
  handleBack: PropTypes.func,
  backupPreviewId: PropTypes.string,
  backupPreviewLoading: PropTypes.bool,
  restoreProposal: PropTypes.func,
  approved: PropTypes.bool,
};

export default HistoryHeader;
