import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M6.74041 2.43252C5.66444 2.84617 4.71176 3.51574 3.9612 4.37683L3.95873 4.37968L4.98687 5.4025C5.60502 6.01745 5.19495 7.07376 4.32379 7.11055L1.04221 7.24913C0.459246 7.27375 -0.0208918 6.7961 0.000700914 6.21302L0.122249 2.93075C0.154516 2.05942 1.20869 1.64387 1.82683 2.25882L2.56403 2.9922C2.57306 2.98214 2.5821 2.97209 2.59117 2.96207C3.53352 1.92044 4.71163 1.10845 6.03568 0.599423C7.83016 -0.0904607 9.7984 -0.187587 11.6521 0.322272C13.5058 0.832131 15.1475 1.92218 16.3367 3.43274C17.526 4.9433 18.2002 6.79499 18.2608 8.71656C18.3213 10.6381 17.765 12.5286 16.6732 14.111C15.5814 15.6935 14.0116 16.8847 12.1937 17.5103C10.3758 18.1358 8.4053 18.1628 6.57095 17.5872C5.21748 17.1626 3.99059 16.4264 2.98454 15.4461C2.92546 15.3886 2.86714 15.3302 2.80961 15.2709C2.746 15.2055 2.68334 15.139 2.62167 15.0715C2.25582 14.6712 2.35493 14.0525 2.79272 13.7324C3.17178 13.4552 3.6799 13.494 4.0547 13.7619C4.11275 13.8034 4.16761 13.8504 4.2183 13.9026L4.26278 13.9479C5.06605 14.7601 6.059 15.3683 7.15888 15.7134C8.59295 16.1634 10.1334 16.1423 11.5547 15.6532C12.9759 15.1642 14.2031 14.2329 15.0567 12.9958C15.9102 11.7586 16.3452 10.2807 16.2979 8.77841C16.2505 7.27615 15.7234 5.82852 14.7937 4.64758C13.8639 3.46664 12.5804 2.61445 11.1313 2.21585C9.68206 1.81724 8.14331 1.89318 6.74041 2.43252Z"
      fill="#7876C1"
    />
    <rect x="7.92383" y="4.85211" width="2" height="6" rx="1" fill="#7876C1" />
    <rect
      x="13.2705"
      y="11.3617"
      width="2"
      height="5.01918"
      rx="1"
      transform="rotate(120 13.2705 11.3617)"
      fill="#7876C1"
    />
  </svg>
);

const HistoryIcon = (props) => <Icon component={svg} {...props} />;

export default HistoryIcon;
