import PropTypes from 'prop-types';
import VersionItem from './VersionItem';

const VersionGroup = ({
  date,
  list,
  groupIndex,
  liveEditedObj,
  backupPreviewId,
  setBackupPreviewId,
  backupPreviewLoading,
  proposalEdited,
}) => {
  return (
    <div className="group">
      <h2 className="title" title={date}>
        .
      </h2>

      {list.map((item, index) => (
        <VersionItem
          key={`list-${groupIndex}-${index}`}
          {...('live' === item._id ? liveEditedObj : item)}
          backupPreviewId={backupPreviewId}
          setBackupPreviewId={setBackupPreviewId}
          backupPreviewLoading={backupPreviewLoading}
          proposalEdited={proposalEdited}
        />
      ))}
    </div>
  );
};

VersionGroup.defaultProps = {
  date: '',
  list: [],
  groupIndex: 0,
  liveEditedObj: {},
  backupPreviewId: '',
  setBackupPreviewId: () => {},
  backupPreviewLoading: false,
  proposalEdited: null,
};

VersionGroup.propTypes = {
  date: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupIndex: PropTypes.number,
  liveEditedObj: PropTypes.object,
  backupPreviewId: PropTypes.string,
  setBackupPreviewId: PropTypes.func,
  backupPreviewLoading: PropTypes.bool,
  proposalEdited: PropTypes.number,
};

export default VersionGroup;
