import { gql } from '@apollo/client';

export const FETCH_PROPOSAL_BACKUPS = gql`
  query FetchProposalBackups($proposalId: String!) {
    fetchProposalBackups(proposalId: $proposalId) {
      _id
      backupTime
      editorName
    }
  }
`;

export const FETCH_PROPOSAL_BACKUP = gql`
  query FetchProposalBackup($backupId: String!) {
    fetchProposalBackup(backupId: $backupId) {
      proposal
    }
  }
`;
