import PropTypes from 'prop-types';
import CircleLoading from 'components/CircleLoading/CircleLoading';

const VersionItem = ({
  _id,
  backupTimestamp,
  time,
  editorName,
  backupPreviewId,
  setBackupPreviewId,
  backupPreviewLoading,
  proposalEdited,
}) => {
  if ('live' !== _id && proposalEdited === backupTimestamp) {
    return null;
  }
  return (
    <div
      onClick={() => setBackupPreviewId(_id)}
      className={`version-item ${'live' === _id ? 'live' : ''} ${
        !backupPreviewLoading && backupPreviewId === _id ? 'active' : ''
      }`}>
      <div className="time">
        {time}
        {backupPreviewId === _id && <CircleLoading loading={backupPreviewLoading} size="small" />}
      </div>
      <div className="editor">{editorName}</div>
    </div>
  );
};

VersionItem.defaultProps = {
  _id: '',
  backupTimestamp: null,
  time: '',
  editorName: '',
  backupPreviewId: '',
  setBackupPreviewId: () => {},
  backupPreviewLoading: false,
  proposalEdited: null,
};

VersionItem.propTypes = {
  _id: PropTypes.string.isRequired,
  backupTimestamp: PropTypes.number,
  time: PropTypes.string,
  editorName: PropTypes.string,
  backupPreviewId: PropTypes.string,
  setBackupPreviewId: PropTypes.func,
  backupPreviewLoading: PropTypes.bool,
  proposalEdited: PropTypes.number,
};

export default VersionItem;
