import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, message, Form, Input, Row, Checkbox, Progress } from 'antd';
import Button from 'components/Button';
import Uploader from 'components/Uploader';
import { resizeImageForS3 } from 'utils/uploadToS3';
import LogoUploadIcon from 'components/Icons/LogoUploadIcon';
import RemoveBinIcon from 'components/Icons/RemoveBinIcon';
import images from 'constants/images';

import './SubUserInfo.scss';

const SubUserInfo = ({ saveUserProfileInfo, user }) => {
  const inputFileRef = useRef(null);
  const [form] = Form.useForm();
  const [upload, uploadStatus] = Uploader();

  const [isSaving, setIsSaving] = useState(false);
  const [image, setImageUrl] = useState(user.profile.image || '');
  const [fullname, setFullName] = useState(user?.profile?.name || '');
  const [termsValue, setTermsValue] = useState(true);

  const [formFields, setFormFields] = useState({
    image,
    fullname,
    terms: termsValue,
  });

  const uploadUserPhoto = () => {
    inputFileRef.current.click();
  };

  const detachUpload = () => {
    setImageUrl('');
    form.setFieldsValue({ image: '' });
  };

  const onImageSelect = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (!file) {
      return;
    }

    if (validity.valid) {
      const size = { width: 640, height: 480 };

      try {
        const reSizedImage = await resizeImageForS3({
          file: file,
          path: 'profile',
          size,
        });

        const { url } = await upload(reSizedImage, `profile/${user._id}`);
        setImageUrl(url);
        form.setFieldsValue({ image: url });
        setFormFields((prev) => ({ ...prev, image: url }));
      } catch (error) {
        message.error(error.message);
      } finally {
        if (inputFileRef.current) {
          inputFileRef.current.value = '';
        }
      }
    }
  };

  const validateFullname = (rule, value, callback) => {
    if (value.length > 0 && value.trim().length === 0) {
      callback('Full name cannot just have spaces!');
    } else {
      callback();
    }
  };

  const submitProfileInfo = (values) => {
    const updatedValues = {
      ...formFields,
      ...values,
      ...{ onBoardComplete: true, onboardingState: 0 },
    };

    setFormFields((prev) => ({ ...prev, ...values }));
    saveUserProfileInfo(updatedValues);

    setIsSaving(true);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => submitProfileInfo(values))
      .catch((errorInfo) => {
        console.error('Validation failed:', errorInfo);
      });
  };

  return (
    <div className="team-member-info-container">
      <div className="team-member-info-content">
        <div className="logo">
          <img src={images.LOGO} alt="prospero-logo" height="18" />
        </div>
        <div className="team-member-info-info text-center">
          <h3>Welcome to Prospero 🥳</h3>
          <div className="color-divider" />
        </div>
        <Form layout="vertical" initialValues={formFields} form={form} autoComplete="off">
          <Col xs={24} className="upload-container">
            <Form.Item label="" colon={false} help="Upload your profile picture (max 2 MB)">
              {uploadStatus.status === 'uploading' ? (
                <Progress type="circle" percent={uploadStatus.percent} />
              ) : (
                <div>
                  {image ? (
                    <div
                      className="logo-uploader round has-image"
                      style={{
                        backgroundImage: `url(${image})`,
                      }}
                      onClick={uploadUserPhoto}>
                      <span
                        className="remove-logo"
                        onClick={(e) => {
                          e.stopPropagation();
                          detachUpload();
                        }}>
                        <RemoveBinIcon />
                      </span>
                    </div>
                  ) : (
                    <div className="logo-uploader round" onClick={uploadUserPhoto}>
                      <span className="upload-icon">
                        <LogoUploadIcon />
                      </span>
                    </div>
                  )}
                </div>
              )}
            </Form.Item>
          </Col>
          <Form.Item
            label="Full name"
            name="fullname"
            labelCol={false}
            rules={[
              { required: true, message: 'Full name cannot be empty!' },
              { validator: validateFullname },
            ]}
            shouldUpdate>
            <Input value={fullname} onChange={() => setFullName()} />
          </Form.Item>
          <Form.Item name="terms" rules={[{ required: !termsValue, message: 'Required!' }]}>
            <Checkbox checked={termsValue} onChange={(e) => setTermsValue(!!e.target.checked)}>
              By creating an account you agree to our{' '}
              <a href="https://www.goprospero.com/terms" rel="noreferrer" target="_blank">
                Terms
              </a>{' '}
              &
              <a
                rel="noreferrer"
                href="https://www.iubenda.com/privacy-policy/50076459"
                target="_blank">
                {' '}
                Privacy Policy
              </a>
              .
            </Checkbox>
          </Form.Item>
          <input
            type="file"
            className="file_bag"
            accept="image/*"
            ref={inputFileRef}
            onChange={onImageSelect}
            style={{ display: 'none', position: 'absolute', left: -10000 }}
          />
          <Row>
            <Button
              type="primary"
              size="large"
              block={true}
              className="info-submit-button submit-this-button"
              danger={true}
              loading={isSaving}
              onClick={handleSubmit}
              text={'Go to Dashboard'}
            />
          </Row>
        </Form>
      </div>
    </div>
  );
};

SubUserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  saveUserProfileInfo: PropTypes.func.isRequired,
};

export default SubUserInfo;
