import PropTypes from 'prop-types';
import './CircleLoading.scss';

// show circle spinning loading animation
const CircleLoading = ({ loading, size }) => {
  if (!loading) {
    return null;
  }

  return <div className={`circle-loading ${size}`} />;
};
CircleLoading.defaultProps = {
  loading: false,
  size: 'medium', // small, medium, large
};

CircleLoading.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.string,
};

export default CircleLoading;
