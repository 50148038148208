import { Skeleton } from 'antd';

const AllVersionsSkeleton = () => {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((f) => (
        <div className="version-skeleton" key={f}>
          <Skeleton.Input style={{ width: 80 }} active size="small" />
          <Skeleton.Input style={{ width: 50 }} active size="small" />
        </div>
      ))}
    </>
  );
};

export default AllVersionsSkeleton;
